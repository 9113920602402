$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px,
);

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px,
);

$font-size-root: 16px;
$font-family-base: 'Open Sans',
sans-serif;

$h3-font-size: 1.625rem;

$border-radius: .25rem;
$border-radius-lg: .3rem;
$border-radius-sm: .2rem;

$primary: #1A2430;
$secondary: #0E1117;
$tertiary: #111921;
$success: #388E3C;
$info: #FFEB3B;
$light: #C9CFD4;
$warning: #FF5722;
$danger: #FF5252;
$dark: #43494e;
$white: #fff;
$gray: #767C81;

$body-bg: $secondary;
$body-color: $white;
$link-color: $white;
$link-hover-color: $white;

$card-bg: $secondary;
$card-color: $white;

$modal-content-bg: $primary;
$modal-content-border-color: $dark;
$modal-content-color: $white;

$hr-border-color: $primary;
$border-color: $primary;

$input-btn-padding-y: .2rem;
$input-btn-padding-x: 2rem;
$btn-border-radius: .09rem;

$dropdown-border-radius: 0.25rem;
$dropdown-font-size: 1.125rem;
$dropdown-spacer: 1rem;
$enable-shadows: false;
$enable-caret: false;

$dropdown-box-shadow: 2px 2px 5px 0px rgba(66, 66, 66, 0.42);
$dropdown-link-active-color: $white;
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;
$dropdown-link-active-bg: $primary;
$dropdown-bg: $primary;

$navbar-dark-color: $primary;
$navbar-dark-toggler-border-color: $white;
$navbar-toggler-border-radius: $dropdown-border-radius;

$link-color: $primary;

$form-feedback-icon-invalid: url("data:image/svg+xml,<svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><defs><style>.cls-1{fill:#{$danger};}</style></defs><title>Artboard 1</title><path class='cls-1' d='M18.52.78a2.68,2.68,0,0,1,3.77,0l.93.92a2.66,2.66,0,0,1,0,3.74l0,0L16.68,12l6.54,6.55a2.65,2.65,0,0,1,0,3.74l0,0-.93.92a2.68,2.68,0,0,1-3.77,0L12,16.69,5.48,23.22a2.68,2.68,0,0,1-3.77,0l-.93-.92a2.66,2.66,0,0,1,0-3.75h0L7.32,12,.79,5.45a2.65,2.65,0,0,1,0-3.74l0,0L1.71.77a2.68,2.68,0,0,1,3.77,0L12,7.31ZM11.23,9.63,3.94,2.33a.49.49,0,0,0-.69,0l-.93.92a.47.47,0,0,0,0,.66h0l7.3,7.32a1.08,1.08,0,0,1,0,1.54L2.32,20.1a.47.47,0,0,0,0,.65h0l.93.92a.49.49,0,0,0,.69,0l7.29-7.31a1.1,1.1,0,0,1,1.54,0h0l7.29,7.3a.49.49,0,0,0,.69,0l.93-.92a.47.47,0,0,0,0-.66h0l-7.31-7.32a1.08,1.08,0,0,1,0-1.54L21.68,3.9a.46.46,0,0,0,0-.65h0l-.93-.92a.5.5,0,0,0-.69,0L12.77,9.63a1.1,1.1,0,0,1-1.54,0Z'/></svg>");

$input-bg: $primary;
$input-color: $white;
$input-border-color: $dark;
$custom-control-label-disabled-color: $secondary;

$input-placeholder-color: rgb(117, 117, 117);

$custom-checkbox-indicator-border-radius: 0.1rem;

$custom-control-indicator-border-color: $dark;
$custom-control-indicator-border-width: 2px;

$custom-control-indicator-checked-color: $secondary;
$custom-control-indicator-checked-bg: var(--white);
$custom-control-indicator-checked-border-color: $secondary;

$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'><path fill='#{$light}' d='M2.17,1.55l-.62.61a.31.31,0,0,0,0,.44h0l6,5.93a.69.69,0,0,0,1,0l6-5.93a.3.3,0,0,0,0-.44h0l-.61-.61a.33.33,0,0,0-.47,0L8.51,6.37a.71.71,0,0,1-1,0L2.63,1.55a.32.32,0,0,0-.46,0M8,4.83,12.35.52a1.79,1.79,0,0,1,2.51,0l.62.62a1.77,1.77,0,0,1,0,2.49h0l-6,5.93a2.13,2.13,0,0,1-3,0l-6-5.93a1.76,1.76,0,0,1,0-2.49h0L1.14.51a1.79,1.79,0,0,1,2.51,0Z'/></svg>");
$custom-select-bg-size: 18px 10px;
$input-padding-x: 1rem;

// $input-btn-padding-x: 1.75rem;

$ds365-header-height: 50px;
$ds365-banner-height: 60px;
$ds365-container-height: 300px;
$ds365-zindex-fixed: 1030;
$ds365-zindex-overlay: 1051;
$ds365-zindex-top: 1050;
